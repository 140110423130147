<template>
  <v-container
    id="diets"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="headers"
      :items="nutritions"
      :search="search"
      :no-data-text="str['no_diets']"
      :no-results-text="str['no_diets']"
      class="elevation-1"
      @click:row="openEditNutrition"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-dialog
            v-model="dialogNutrition"
            persistent
            max-width="750px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="editPermission"
                color="success"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="openNewNutrition()"
              >
                {{ str['add'] }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ str['diet_and_supplementation'] }} {{ nutritionToEdit && nutritionToEdit.date ? '(' + nutritionToEdit.date + ')' : ''  }}
                </span>
              </v-card-title>

              <v-col cols="12">
                <v-text-field
                  v-model="nutritionName"
                  v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
                  name="input-7-1"
                  :label="str['name']"
                  class="purple-input"
                  style="margin-top: 10px;"
                  hide-details
                />
              </v-col>

              <div
                v-if="!useNutritionPdf"
                v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
              >
                <v-col
                  v-if="showNutritionLink && nutritionExtraData"
                  cols="12"
                >
                  <v-text-field
                    v-model="nutritionExtraData.url"
                    name="input-7-1"
                    :label="str['link_url']"
                    class="purple-input"
                    style="margin-top: 10px;"
                    hide-details
                  />
                </v-col>
                <v-col
                  v-if="showNutritionLink && nutritionExtraData"
                  cols="12"
                >
                  <v-textarea
                    v-model="nutritionExtraData.description"
                    :label="str['link_description']"
                    rows="3"
                    hide-details
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="nutritionSupplementation"
                    :label="str['supplementation']"
                    rows="3"
                    hide-details
                  />
                </v-col>
                <v-col
                  v-if="editPermission"
                  cols="12"
                >
                  <v-btn
                    color="success"
                    @click="addNutritionNewItem()"
                  >
                    {{ str['add_day'] }}
                  </v-btn>
                </v-col>
                <v-col
                  v-if="!nutritionItems || (nutritionItems && !nutritionItems.length)"
                  cols="12"
                  class="text-center primary--text"
                  style="min-height: 200px; padding-top: 40px;"
                >
                  {{ str['empty_list'] }}
                </v-col>
                <v-col
                  v-for="(nutritionItem, index) in nutritionItems"
                  :key="index"
                  cols="12"
                >
                  <div class="nutrition-item-container">
                    <div
                      v-if="editPermission"
                      @click="removeNutritionItem(index)"
                      class="nutrition-item-remove-icon"
                    >
                      <i class="mdi mdi-trash-can-outline"></i>
                    </div>
                    <v-text-field
                      v-model="nutritionItem.name"
                      name="input-7-1"
                      :label="str['name']"
                      class="purple-input"
                      style="margin-top: 10px;"
                      hide-details
                    />
                    <v-textarea
                      v-model="nutritionItem.description"
                      :label="str['description']"
                      rows="5"
                      hide-details
                      class="mt-3"
                    />
                  </div>
                </v-col>
              </div>

              <div
                v-if="useNutritionPdf"
                v-bind:style="{'pointer-events': editPermission ? '' : 'none'}"
              >
                <div
                  v-if="editPermission"
                  style="padding: 20px; display: flex; justify-content: center; align-items: center;"
                >
                  <v-btn
                    color="blue"
                    class="mr-0"
                  >
                    {{ str['add_pdf'] }}
                  </v-btn>
                  <input
                    id="input-pdf-value"
                    type="file"
                    accept="application/pdf,application/vnd.ms-excel"
                    @change="loadNutritionPdf"
                    style="opacity: 0; position: absolute; height: 45px; width: 160px;"
                  />
                </div>
                <div style="padding: 0 0 20px;">
                  <iframe
                    v-if="nutritionPdfValue"
                    v-bind:src="nutritionPdfValue"
                    style="width: 100%; height: 50vh;"
                  />
                </div>
              </div>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="dialogNutrition = null"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  v-if="editPermission"
                  :disabled="!nutritionName"
                  color="success"
                  @click="saveNutrition()"
                >
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.url="{ item }">
        <div
          class="text-center"
          style="width:300px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin: 0 auto;"
        >
          <a
            target="_blank"
            :href="item.url"
            @click.stop
          >
            {{ item.url }}
          </a>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-0"
          color="error"
          @click.stop="deleteNutrition(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    data () {
      const user = Utils.getUser()
      const editPermission = Utils.hasPermission('base_nutrition_edit')
      const headers = [
        { text: window.strings['id'], value: 'id', align: 'left' },
        { text: window.strings['date'], value: 'date', align: 'center' },
        { text: window.strings['name'], value: 'name', align: 'center' },
      ]

      if (editPermission) {
        headers.push({ text: window.strings['actions'], value: 'actions', sortable: false, align: 'center' })
      }

      let showNutritionLink = false
      if (user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.show_link) {
        showNutritionLink = true
      }

      let useNutritionPdf = false
      if (user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.pdf) {
        useNutritionPdf = true
      }

      return {
        str: window.strings,
        user: user,
        editPermission: editPermission,
        nutritions: [],
        dialogNutrition: false,
        search: '',
        headers: headers,
        nutritionName: '',
        nutritionItems: [],
        nutritionSupplementation: '',
        showNutritionLink: showNutritionLink,
        nutritionExtraData: null,
        nutritionToEdit: null,
        useNutritionPdf: useNutritionPdf,
        nutritionPdfValue: '',
      }
    },
    watch: {
      dialogNutrition (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }
        val || this.closeNutrition()
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.$isLoading(true)
      this.getNutritions()
    },
    methods: {
      getNutritions: function () {
        const self = this
        const nutritionConfiguration = this.user.configurations && this.user.configurations.nutrition ? this.user.configurations.nutrition : false

        let filtered = true
        if (nutritionConfiguration && nutritionConfiguration.show_type) {
          filtered = false
        }

        const payload = {
          id: null,
          filtered: filtered,
          fields: ['id', 'date', 'name', 'type']
        }

        Api.getNutritionPlans(payload, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.nutritions = response.data
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openNewNutrition () {
        this.setData()
        this.nutritionToEdit = null
        this.dialogNutrition = true
        this.resetPdfInput()
      },
      openEditNutrition (nutrition) {
        const self = this
        const payload = {
          id: nutrition.id
        }
        this.$isLoading(true)
        Api.getNutritionPlan(payload, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const src = response.data[0]
            self.setData(src)
            self.nutritionToEdit = src
            self.dialogNutrition = true
            self.resetPdfInput()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      resetPdfInput: function () {
        if (this.useNutritionPdf) {
          setTimeout(function () {
            if (document.getElementById('input-pdf-value')) {
              document.getElementById('input-pdf-value').value = ''
            }
          })
        }
      },
      setData: function (nutrition) {
        const self = this
        try {
          if (nutrition) {
            this.nutritionName = nutrition.name

            if (this.useNutritionPdf) {
              this.nutritionPdfValue = nutrition.description
            } else {
              const descriptionParse = JSON.parse(nutrition.description)
              this.nutritionItems = descriptionParse.items
              this.nutritionSupplementation = descriptionParse.supplementation

              if (this.showNutritionLink) {
                let extraData = {
                  url: '',
                  description: '',
                }
                if (nutrition && nutrition.extra_data) {
                  if (typeof nutrition.extra_data === 'string') {
                    extraData = JSON.parse(nutrition.extra_data)
                  } else {
                    extraData = nutrition.extra_data
                  }
                }
                this.nutritionExtraData = extraData
              }
            }
          } else {
            resetNutritionFields()
          }
        } catch (error) {
          resetNutritionFields()
        }

        function resetNutritionFields () {
          self.nutritionName = ''
          self.nutritionItems = []
          self.nutritionSupplementation = ''

          if (self.showNutritionLink) {
            self.nutritionExtraData = {
              url: '',
              description: '',
            }
          }

          if (self.useNutritionPdf) {
            self.nutritionPdfValue = ''
          }
        }
      },
      closeNutrition () {
        this.nutritionToEdit = null
        this.dialogNutrition = false
      },
      addNutritionNewItem: function () {
        this.nutritionItems.push({
          name: '',
          description: '',
        })
      },
      removeNutritionItem: function (index) {
        this.nutritionItems.splice(index, 1)
      },
      saveNutrition: function () {
        const self = this
        const data = this.getNutritionData()

        this.$isLoading(true)

        if (data.id) {
          Api.editNutritionPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.dialogNutrition = false
              self.getNutritions()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        } else {
          delete data.id
          Api.newNutritionPlan(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.dialogNutrition = false
              self.getNutritions()
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      getNutritionData: function () {
        let nutrition = null

        if (this.useNutritionPdf) {
          nutrition = {
            name: this.nutritionName,
            description: this.nutritionPdfValue,
            type: 1,
            base: 1,
          }

          if (this.nutritionToEdit && this.nutritionToEdit.id) {
            nutrition.id = this.nutritionToEdit.id
          }
        } else {
          nutrition = {
            name: this.nutritionName,
            description: JSON.stringify({
              items: this.nutritionItems,
              supplementation: this.nutritionSupplementation,
            }),
            type: 1,
            base: 1,
          }

          if (this.nutritionToEdit && this.nutritionToEdit.id) {
            nutrition.id = this.nutritionToEdit.id
          }

          if (this.showNutritionLink && this.nutritionExtraData) {
            if (typeof this.nutritionExtraData === 'string') {
              nutrition.extra_data = this.nutritionExtraData
            } else {
              nutrition.extra_data = JSON.stringify(this.nutritionExtraData)
            }
          }
        }

        return nutrition
      },
      deleteNutrition (nutrition) {
        const self = this
        const nutritionId = nutrition.id
        const payload = {
          id: nutritionId,
        }

        this.$confirm(
          window.strings['do_you_want_delete_diet'] + (nutrition ? (' "' + nutrition.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.deleteNutritionPlan(payload, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.nutritions.length; i++) {
                if (self.nutritions[i].id === nutritionId) {
                  self.nutritions.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      loadNutritionPdf(e) {
        const self = this
        if (!e.target.files.length) {
          return false
        }
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
          self.nutritionPdfValue = e.target.result
        }
      }
    },
  }
</script>
